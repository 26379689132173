














































































import Navbar from "@/components/Core/Navbar.vue";
import Loading from "@/components/Web/Loading.vue";
import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";
import { Web } from "@/store/web";
import { Component, Vue, Watch } from "vue-property-decorator";
import _ from 'lodash'
import XLSX from 'xlsx' // import xlsx
import {Result} from '@/store/result'
import {CoreResult} from '@/store/core_result'
@Component({
  components: {
    Navbar,
    Loading,
  },
  computed: {}
})

export default class TestDevClass extends Vue {

    agency:any = null
    assignments:any = null
    year:any = null;
    issues:any = [];
    response:boolean  = false;
    score:any = ''
    score30:number = 0
    chooseAssignId:number = 1;
    allUser:number = 0;

    async getIssue(){
      this.assignments = await Core.getHttp(`/api/iit/v2/assessmentissues/?year=${this.year.id}`)
      this.chooseAssignId = this.assignments[0].id
      this.issues = await CoreResult.getIssueIIT(this.year.id,this.agency.id)
      this.score = await CoreResult.getScrollAll();
      this.score30 = await CoreResult.getScoreEIT();
      console.log(this.issues );
    }



    async created(){
      this.agency = await Core.getHttp(`/api/ita/v1/agency/${this.$route.query.agency}/`)
      this.year = await Core.getHttp(`/api/iit/v2/year/${this.$route.query.year}/`)
      await this.getIssue();
      await this.getUserDone();

      this.response = true;

    }

    async getUserDone(){

      let user = await Core.getHttp(`/api/iit/v2/ansewer/user/?year=${this.year.id}&agency=${this.agency.id}`)
      this.allUser = user.length
    }

    async onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.issues)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb,'export.xlsx')
    }





}
